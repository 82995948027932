// Hintergrundfarbe ändern umfärben
const komplette = document.getElementById('komplette');
// gsap.timeline({
//     scrollTrigger: {
//         id: 'kompletteHintergrundfarbe',
//         trigger: komplette,
//         start: 'top top',
//         end: 'top top',
//         onEnter: () => document.body.setAttribute('data-hintergrund', 'mitternachtsblau'),
//         onLeaveBack: () => document.body.setAttribute('data-hintergrund', 'rauchweiss'),
//         // markers: true,
//     }
// });

// https://greensock.com/forums/topic/27001-scrubbing-animation-and-moving-viewport-with-scrolltrigger/
// https://greensock.com/forums/topic/36042-motionpath-target-multiple-objects-and-gap-between-on-one-path/

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const gruppen = gsap.utils.toArray('.komplette__stichwoerter');

var kompletteStichwoerterInitialisiert = false;

function kompletteStichwoerterInitialisieren() {
    gruppen.forEach((gruppe, i) => {
        const pfad = gruppe.querySelector('.komplette__pfad--' + layoutAktuell);
        const svg = gruppe.querySelector('.komplette__svg');
        const stichwoerter = gsap.utils.toArray('.komplette__stichwort', gruppe);
        const stichwoerterAnzahl = stichwoerter.length;
        
        stichwoerter.forEach((stichwort, j) => {
            if (kompletteStichwoerterInitialisiert == true) {
                let scrolltrigger = ScrollTrigger.getById('kompletteStichwoerter-' + i + '_' + j);
                scrolltrigger.revert();
            }
            gsap.timeline({
                scrollTrigger: {
                    id: 'kompletteStichwoerter-' + i + '_' + j,
                    trigger: svg,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 0.3,
                    // markers: true
                }
            })
            .to(stichwort, {
                motionPath: {
                    path: pfad,
                    align: pfad,
                    alignOrigin: [0.5, 0.5],
                    start: j * -1 / stichwoerterAnzahl,
                    end: (j * -1 / stichwoerterAnzahl) - 1  // 1 is the end
                },
                immediateRender: true,
                ease: 'none',
            }, 0)
        });
    });
    kompletteStichwoerterInitialisiert = true;
}

window.addEventListener('eventLayoutaenderung', function() {
    kompletteStichwoerterInitialisieren();
});
window.addEventListener('DOMContentLoaded', function eventlistenerKompletteInitialisieren() {
    kompletteStichwoerterInitialisieren();
    window.removeEventListener('DOMContentLoaded', kompletteStichwoerterInitialisieren);
});

if (komplette !== null) {
    komplette.addEventListener('mouseover', function() {
        document.body.setAttribute('data-maushintergrund', 'mitternachtsblau');
    });
}